import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import LoginInjector from '../../LoginInjector';
import ParentsUtil from '../../../../library/util/ParentsUtil';
import DesktopLayerPopup from '../../DesktopLayerPopup';
import * as MemberAction from 'src/features/member/MemberAction';
import MemberLogout from 'src/features/member/MemberLogout';
import MyPageKidInfoPop from 'src/features/mypage/mypagekidinfo/MyPageKidInfoPop';
import $ from 'jquery';
import { routes } from 'src/constants/routes';

class UserInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logout: false,
    };

    this.onClickMyInfo = this.onClickMyInfo.bind(this);
    this.onClickLogin = this.onClickLogin.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
    this.onClickStudent = this.onClickStudent.bind(this);
    this.onClickJoin = this.onClickJoin.bind(this);
    this.onClickNoStudent = this.onClickNoStudent.bind(this);
  }

  componentDidMount() {
    $('div.select-mode > button', this.currentDom).on('click', function () {
      if (!$(this).parent().hasClass('on')) {
        $(this).parent().addClass('on');
        if ($(this).parent().hasClass('off')) {
          $(this).parent().removeClass('on');
        }
      } else {
        $(this).parent().removeClass('on');
      }
      return false;
    });

    $('div.select-mode', this.currentDom).on('mouseleave', function () {
      $(this).removeClass('on');
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.userInfo.isLogin === true &&
      prevProps.userInfo.isLogin === false
    ) {
      this.setState({
        logout: false,
      });
      $('div.select-mode > button', this.currentDom).on('click', function () {
        if (!$(this).parent().hasClass('on')) {
          $(this).parent().addClass('on');
          if ($(this).parent().hasClass('off')) {
            $(this).parent().removeClass('on');
          }
        } else {
          $(this).parent().removeClass('on');
        }
        return false;
      });
    }
  }

  loginAfterPush(url) {
    if (this.props.userInfo.isLogin === true) {
      this.props.history.push(url);
    } else {
      DesktopLayerPopup.login({
        redirect: url,
        historyType: 'push',
        history: this.props.history,
      });
    }
  }

  onClickNoStudent() {
    const { studentList } = this.props;
    let newProps = { ...this.props };
    delete newProps.studentInfo;
    if (studentList.length === 0) {
      DesktopLayerPopup.modal(<MyPageKidInfoPop {...newProps} />);
    }
  }

  onClickMyInfo(e) {
    e.preventDefault();
    this.loginAfterPush(routes.myPage.main);
    return false;
  }

  onClickJoin(e) {
    e.preventDefault();
    this.props.history.push(routes.member.memberJoinStep01);
    return false;
  }

  onClickLogin(e) {
    e.preventDefault();
    DesktopLayerPopup.login({
      historyType: 'push',
      history: this.props.history,
    });
  }

  onClickStudent(e, studentInfo) {
    e.preventDefault();

    this.props.selectedStudent(studentInfo);
    $('div.select-mode > button', this.currentDom).click();
  }

  onClickLogout(e) {
    e.preventDefault();
    this.setState({
      logout: true,
    });
  }

  onClickRecruitTeacher = (e) => {
    e.preventDefault();
    this.props.history.push(routes.customer.teacher);
  };

  onClickPromotionTeacher = (e) => {
    e.preventDefault();
    this.props.history.push(routes.customer.promotionTeacher);
  };

  onClickElementaryCatsTeacher = (e) => {
    e.preventDefault();
    window.open('https://danbiedu.career.greetinghr.com/o/114970', '_blank');
  };

  onClickJoinAgency = (e) => {
    e.preventDefault();
    window.open('https://danbiedu.career.greetinghr.com/o/97353', '_blank');
  };

  onClickCathome = (e) => {
    e.preventDefault();
    window.open(
      'https://www.catsenglish.net/parents/campaign?code=24B054&utm_source=wink&utm_medium=banner&utm_campaign=PC&utm_content=catseng',
      '_blank'
    );
  };

  renderLoginMenu() {
    return (
      <div className="login-wrapper">
        {this.renderSelectChild()}
        <ul className="util">
          <li>
            <a href="#" onClick={this.onClickMyInfo}>
              <span>내 정보</span>
            </a>
          </li>
          <li>
            <a href="#" onClick={this.onClickLogout}>
              <span>로그아웃</span>
            </a>
          </li>
          {this.renderBannerMenu()}
        </ul>
      </div>
    );
  }

  renderNotLoginMenu() {
    return (
      <ul className="util">
        <li>
          <a href="#" onClick={this.onClickLogin.bind(this)}>
            <span>로그인</span>
          </a>
        </li>
        <li>
          <a href="#" onClick={this.onClickJoin}>
            <span>회원가입</span>
          </a>
        </li>
        {this.renderBannerMenu()}
      </ul>
    );
  }

  renderBannerMenu() {
    return (
      <Fragment>
        <li>
          <a href="#" onClick={this.onClickRecruitTeacher}>
            <span className="banner-text">윙크 선생님 모집</span>
          </a>
        </li>
        <li>
          <a href="#" onClick={this.onClickPromotionTeacher}>
            <span className="banner-text">홍보 선생님 모집</span>
          </a>
        </li>
        <li>
          <a href="#" onClick={this.onClickElementaryCatsTeacher}>
            <span className="banner-text">초등 캐츠 선생님 모집</span>
          </a>
        </li>
        <li>
          <a href="#" onClick={this.onClickJoinAgency}>
            <span className="banner-text">판매 대리점 모집</span>
          </a>
        </li>
        <li>
          <a href="#" onClick={this.onClickCathome}>
            <span className="banner-blue-link">초·중등 전문학습</span>
          </a>
        </li>
      </Fragment>
    );
  }

  renderSelectChild() {
    const { userInfo, studentList } = this.props;
    let selectedStudentLabel = '자녀 미등록';
    if (userInfo.studentInfo && studentList.length > 0) {
      selectedStudentLabel =
        ParentsUtil.getActorHumanName(userInfo.studentInfo) +
        '(' +
        ParentsUtil.getActorAge(userInfo.studentInfo) +
        '세)';
    }

    return (
      <div className="select-mode" onClick={this.onClickNoStudent}>
        <button type="button" disabled={studentList.length === 0}>
          <i>{selectedStudentLabel}</i>
        </button>
        <div>
          <ul>
            {studentList.map((info, idx) => (
              <li key={idx} onClick={(e) => this.onClickStudent(e, info)}>
                <button type="button">
                  <i>
                    {ParentsUtil.getActorHumanName(info)}(
                    {ParentsUtil.getActorAge(info)}세)
                  </i>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="header" ref={(ref) => (this.currentDom = ref)}>
        <div className="util-wrapper">
          <div className="util-container">
            {this.props.userInfo.isLogin
              ? this.renderLoginMenu()
              : this.renderNotLoginMenu()}
          </div>
        </div>
        {this.state.logout && <MemberLogout {...this.props} />}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    studentList: state.MemberReducer.studentList,
    history: ownProps.history,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    selectedStudent: (loginStudentInfo) => {
      dispatch(MemberAction.selectedStudent(loginStudentInfo));
    },
    logout: () => {
      dispatch(MemberAction.logout());
    },
    setStudentMemberAndLoginSecondary: (name, isMale, dateOfBirth) => {
      dispatch(
        MemberAction.setStudentMemberAndLoginSecondary(
          name,
          isMale,
          dateOfBirth
        )
      );
    },
    clearMemberAction: () => {
      dispatch(MemberAction.clearAction());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginInjector(UserInfo));
